module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N8B664F","gtmAuth":"ED85iDPmoUJiSEJb1DL6XA","gtmPreview":"env-8","includeInDevelopment":true,"routeChangeEventName":"virtPageView","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"standalone","icon":"static/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"928d46cadf6ccb027b6ec40c8c8b6368"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["de","fr","it","en"],"defaultLanguage":"de","generateDefaultLanguagePage":true,"redirect":true,"siteUrl":"https://s.onelog.ch","i18nextOptions":{"defaultNS":"common","fallbackLng":"de","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/agb","languages":["de","fr","it","en","pt","es","sr","hr","bs","al","ua","ru"]},{"matchPath":"/privacy-policy","languages":["de","fr","it","en","pt","es","sr","hr","bs","al","ua","ru"]},{"matchPath":"/impressum","languages":["de","fr","it","en","pt","es","sr","hr","bs","al","ua","ru"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"hxs":"(max-height: 520px)","hsm":"(min-height: 521px) and (max-height: 767px)","hmd":"(min-height: 768px) and (max-height: 1319px)","hxl":"(min-height: 1320px)","mobile":"(max-width: 767px)","tablet":"(min-width: 768px) and (max-width: 1024px)","desktop":"(min-width: 1025px)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
